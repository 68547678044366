import React, { useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Login from "./Pages/Login";
import Dashboard from "./Pages/Dashboard";
import Account from "./Pages/Account";
import Assets from "./Pages/Assets";
import Activity from "./Pages/Activity";
import Deposit from "./Pages/Deposit";
import Support from "./Pages/Support";
import Setting from "./Pages/Setting";
import Withdraw from "./Pages/Withdraw";
import Earn from "./Pages/Earn";
import Identity_verfication from "./Pages/Identity_verfication";
import Managment from "./Pages/Managment";
import Account_activity from "./Pages/Account_activity";
import Depost_inr from "./Pages/Depost_inr";
import Withdraw_inr from "./Pages/Withdraw_inr";
import Notification from "./Pages/Notification";
import {
  fetchConditionsData,
  fetchUserHandler,
  fetchWalletHandler,
  qrLoginInfoHandler,
} from "./utils/actionHandler";
import { useDispatch, useSelector } from "react-redux";
import { setLoginId } from "./redux/qrLoginSlice";
import { updateBalance } from "./utils/apis";
import Convert from "./Pages/Convert";
import Rewards from "./Pages/Rewards";
import Referral from "./Pages/Referral";
import Bank_verification from "./Pages/Bank_verification";
import ScrollToTop from "./Component/ScrollToTop";
import StakingAgreement from "./Pages/StakingAgreement";

const RoutesMain = () => {
  const dispatch = useDispatch();
  const { login, user, sessionId, userdata } = useSelector((state) => state.user);
  const { loginId } = useSelector((state) => state.qrlogin);

  // useEffect(() => {
  //   // console.log(loginId, "loginid");
  //   if (!login && !loginId) {
  //     qrLoginInfoHandler("", "create", dispatch, (res) => {
  //       // console.log(res, "qr login res in routes");
  //       if (typeof res != "boolean") {
  //         if (res) {
  //           dispatch(setLoginId(res?.data?.loginId));
  //         }
  //       }
  //     });
  //   }
  // }, [login, loginId]);

  useEffect(() => {
    if (login && user?.mobile) {
      fetchWalletHandler(user?.mobile, sessionId, dispatch);
      fetchUserHandler(user?.mobile, user?.tokenId, dispatch);
      // updateBalance(user.mobile);
    }
    if(login && user?.email && !user?.mobile){
      fetchUserHandler(user?.email, user?.tokenId, dispatch);
      fetchWalletHandler(user?.email,sessionId, dispatch);
    }
    fetchConditionsData(dispatch)
  }, [user, login]);

  return (
    <>
    <ScrollToTop /> 
      <Routes>
        <Route exact path="/" element={<Login />}>
          <Route exact path="/login" element={<Login />} />
          <Route
            exact
            path="/invite"
            element={<Protected element={<Login />} islogin={login} />}
          >
            <Route
              exact
              path=":id"
              element={<Protected element={<Login />} islogin={login} />}
            />
          </Route>
        </Route>
        <Route
          exact
          path="/dashboard"
          element={<Protected element={<Dashboard />} islogin={login} />}
        />
        <Route
          exact
          path="/account"
          element={<Protected element={<Account />} islogin={login} />}
        />
        <Route
          exact
          path="/assets"
          element={<Protected element={<Assets />} islogin={login} />}
        />
        <Route
          exact
          path="/activity"
          element={<Protected element={<Activity />} islogin={login} />}
        />
        <Route
          exact
          path="/deposit"
          element={<Protected element={<Deposit />} islogin={login} />}
        />
        <Route
          exact
          path="/withdraw"
          element={<Protected element={<Withdraw />} islogin={login} />}
        />
        <Route
          exact
          path="/earn"
          element={<Protected element={<Earn />} islogin={login} />}
        />
        <Route
          exact
          path="/support"
          element={<Protected element={<Support />} islogin={login} />}
        />
        <Route
          exact
          path="/setting"
          element={<Protected element={<Setting />} islogin={login} />}
        />
        <Route
          exact
          path="/identity-verfication"
          element={
            <Protected element={<Identity_verfication />} islogin={login} />
          }
        />
        <Route
          exact
          path="/bank-verfication"
          element={
            <Protected element={<Bank_verification />} islogin={login} />
          }
        />
        <Route
          exact
          path="/managment"
          element={<Protected element={<Managment />} islogin={login} />}
        />
        <Route
          exact
          path="/account_activity"
          element={<Protected element={<Account_activity />} islogin={login} />}
        />
        <Route
          exact
          path="/deposit_fiat"
          element={<Protected element={<Depost_inr />} islogin={login} />}
        />
        <Route
          exact
          path="/withdraw_fiat"
          element={<Protected element={<Withdraw_inr />} islogin={login} />}
        />
        <Route
          exact
          path="/notification"
          element={<Protected element={<Notification />} islogin={login} />}
        />

        <Route
          exact
          path="/convert"
          element={<Protected element={<Convert />} islogin={login} />}
        />
        <Route
          exact
          path="/rewards"
          element={<Protected element={<Rewards />} islogin={login} />}
        />
        <Route
          exact
          path="/referral"
          element={<Protected element={<Referral />} islogin={login} />}
        />      
        <Route
          exact
          path="/stakingagreement"
          element={<Protected element={<StakingAgreement />} islogin={login} />}
        />
      </Routes>
    </>
  );
};

const Protected = ({ element, islogin }) => {
  return islogin ? element : <Navigate to={"/login"} />;
};

export default RoutesMain;
